import { styled } from '@mui/material/styles';
import { Box, Paper, Typography } from '@mui/material';

export const ExtraContainer = styled(Paper)({
  width: '100%',
  marginBottom: '30px',
});

export const ExtrasTitle = styled(Typography)({
  fontSize: '12px',
  letterSpacing: '.5px',
  textAlign: 'center',
  marginBottom: '10px',
  marginTop: '30px',
});

export const ListContainer = styled(Box)({
  padding: '10px',
  display: 'flex',
  flexDirection: 'column',
});

export const Row = styled(Box)({
  padding: '10px',
  display: 'flex',
  justifyContent: 'space-between',
});

export const NameContainer = styled(Typography)({
  fontSize: '12px',
  letterSpacing: '.5px',
});

export const PriceContainer = styled(Typography)({
  fontWeight: 600,
  fontSize: '14px',
  letterSpacing: '.5px',
});
