import { styled } from '@mui/material/styles';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { COLOR } from '@utils/colors';
import { defaultProductImage } from '@components/ProductCard/product-card';
import { SCREEN_WIDTH_MD } from '@state/store/constants';

export const HeaderContainer = styled(Box)(({ src }: { src?: string }) => {
  const isSmallScreen = useMediaQuery(`(max-width:${SCREEN_WIDTH_MD}px)`);

  return {
    backgroundColor: COLOR.Zereno,
    background: `url(${src || defaultProductImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    width: '100%',
    paddingBottom: isSmallScreen ? '100%' : '50%',
    position: 'fixed',
    top: 0,
  };
});

export const FixedNavBarContainer = styled(Box)({
  position: 'fixed',
  top: 10,
  right: 10,
  zIndex: 1,
});

export const DialogContent = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  backgroundColor: COLOR.Zereno,
});

export const ProductName = styled(Typography)({
  fontWeight: 600,
  fontSize: '20px',
  letterSpacing: '.5px',
});

export const BodyContainer = styled(Box)(() => {
  const isSmallScreen = useMediaQuery(`(max-width:${SCREEN_WIDTH_MD}px)`);

  return {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    alignItems: 'center',
    padding: '0 25px',
    position: 'relative',
    marginTop: isSmallScreen ? '100%' : '50%',
    backgroundColor: COLOR.backgroundMenu,

    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: '-20px',
      left: 0,
      width: '100%',
      height: '20px',
      backgroundColor: COLOR.backgroundMenu,
      borderTopLeftRadius: '20px',
      borderTopRightRadius: '20px',
      boxShadow: `0px -1px 1px ${COLOR.LightGray}`,
    },
  };
});

export const ProductDescription = styled(Typography)({
  width: '100%',
  fontSize: '16px',
  letterSpacing: '.5px',
  marginTop: '30px',
  textAlign: 'center',
  color: COLOR.DarkGray,
  textIndent: '2rem',
});

export const PricesContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  width: '100%',
  marginTop: '20px',
  marginBottom: '20px',
});

export const Price = styled(Typography)({
  fontSize: '22px',
  fontWeight: 600,
  letterSpacing: '.5px',
  textAlign: 'right',
});
