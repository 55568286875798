import { LANGUAGE, PRICE_VARIANT, PRODUCT_VIEW } from '@state/store/constants';

export type Language = (typeof LANGUAGE)[keyof typeof LANGUAGE];

export type ProductListView = (typeof PRODUCT_VIEW)[keyof typeof PRODUCT_VIEW];

export type PriceVariantType = (typeof PRICE_VARIANT)[keyof typeof PRICE_VARIANT];

export type Name = {
  [LANGUAGE.EN]: string;
  [LANGUAGE.ES]: string;
};

export const EXTRAS = {
  PLANT_BASED_MILK: 'PLANT_BASED_MILK',
  ESPRESSO_SHOT: 'ESPRESSO_SHOT',
  COCONUT_CREAM: 'COCONUT_CREAM',
  COLD_BREW: 'COLD_BREW',
  ICECREAM_SCOOP: 'ICECREAM_SCOOP',
} as const;

export const MENU_ICONS = {
  BAKERY: 'BAKERY',
  COFFEE_CUP: 'COFFEE_CUP',
  FORKS: 'FORKS',
  SHOP: 'SHOP',
} as const;

export type ExtraType = (typeof EXTRAS)[keyof typeof EXTRAS];

export type MenuSection = {
  id: string;
  name: Record<Language, string>;
};

export type PriceVariant = {
  type: PriceVariantType;
  prices: {
    id: string;
    name?: Name;
    price: number;
  }[];
};

export type Product = {
  id: string;
  name: Record<Language, string>;
  description: Record<Language, string>;
  price?: number;
  priceVariant?: PriceVariant;
  imageUrl?: string;
  extras?: ExtraType[];
  volume?: number;
};

export type ProductGroup = {
  groupId: string;
  sectionId: string;
  // TODO: cambiar las translations por el tipo Name
  groupName?: Record<Language, string>;
  products: Product[];
};

export type Extra = {
  key: ExtraType;
  name: Record<Language, string>;
  price: number;
};

export type Menu = {
  id: string;
  name: Name;
  icon: string;
};

export type Description = Name;

export type ExtraItem = {
  id: string;
  name: Name;
  price: number;
  order: number;
};

type ExtraMenu = {
  id: string;
  active: boolean;
  items: ExtraItem[];
};

export type PublicProduct = {
  id: string;
  active: boolean;
  name: Name;
  description: Description;
  imageUrl?: string;
  thumbUrl?: string;
  volume?: number;
  priceVariant: PriceVariant;
  productExtras: {
    extra: ExtraMenu;
  }[];
  isVegetarian: boolean;
};

export type PublicProductCategory = {
  id: string;
  name: Name;
  order: number;
  products: PublicProduct[];
};

export type PublicSection = {
  id: string;
  name: Name;
  showSectionName: boolean;
  active: boolean;
  productCategory: PublicProductCategory[];
  inCurrentDaypart: boolean;
  daypart: string;
};

export type Message = {
  [LANGUAGE.EN]: string;
  [LANGUAGE.ES]?: string;
  visibleFrom: string;
  visibleTo: string;
};

export type MenuContent = {
  id: string;
  name: Name;
  icon: string;
  sections: PublicSection[];
  messages: Message[];
};

export type Banner = {
  id: string;
  key: string;
  image: Name;
  linkTo?: string;
  isPopUp: boolean;
  visibleFrom?: string;
  visibleTo?: string;
};

export type MenuSetting = {
  id: string;
  key: string;
  value: string;
};

export enum THEMES {
  NONE = 'NONE',
  HALLOWEEN = 'HALLOWEEN',
}
