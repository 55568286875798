import { ReactNode } from 'react';
import Header from '@components/Header';
import { Box } from '@mui/material';
import ProductSectionLinks from '@components/SectionLinks';
import { CONTAINER_MAX_WIDTH_MOBILE } from 'theme';

type TemplateProps = {
  pageTitle?: string;
  backRoute?: string;
  children: ReactNode;
  isMenuPage?: boolean;
};

const Template = ({ pageTitle, backRoute, isMenuPage, children }: TemplateProps) => {
  return (
    <>
      <Box
        sx={{
          position: 'relative',
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Header pageTitle={pageTitle} backRoute={backRoute} />
        {isMenuPage && <ProductSectionLinks />}
      </Box>
      <Box
        id="containerElement"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          overflow: 'auto',
          flexGrow: 1,
        }}
      >
        <Box
          sx={{
            ...(!isMenuPage && { display: 'flex' }),
            width: '100%',
            maxWidth: `${CONTAINER_MAX_WIDTH_MOBILE}px`,
          }}
        >
          {children}
        </Box>
      </Box>
    </>
  );
};

export default Template;
