import { useCallback, useEffect, useRef } from 'react';
import { ScrollMenu, VisibilityContext, getItemsPos } from 'react-horizontal-scrolling-menu';
import { scroller } from 'react-scroll';

import { Box } from '@mui/material';
import 'react-horizontal-scrolling-menu/dist/styles.css';

import ScrollLink from '@components/SectionLinks/ScrollLink';
import { DragManager } from './drag-manager';
import { useStore } from '@state/store';
import { useScroll } from '@state/scroll';
import { CONTAINER_MAX_WIDTH_MOBILE } from 'theme';
import { COLOR } from '@utils/colors';

type ScrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;

const SectionLinks = () => {
  const apiRef = useRef({} as ScrollVisibilityApiType);
  const dragState = useRef(new DragManager());
  const { menuContent, language } = useStore();
  const { selectedSectionId } = useScroll();

  const handleScrollTo = (id: string) => {
    scroller.scrollTo(id, {
      duration: 800,
      smooth: 'easeOutQuint',
      containerId: 'containerElement',
    });
  };

  const moveListItemToCenter = useCallback(() => {
    if (!selectedSectionId) {
      return;
    }

    apiRef.current.scrollToItem(
      apiRef.current.getItemById(selectedSectionId),
      'smooth',
      'center',
      'nearest',
      {
        duration: 1000,
        behavior: {},
      }
    );
  }, [selectedSectionId]);

  useEffect(() => {
    if (!selectedSectionId) {
      return;
    }
    moveListItemToCenter();
  }, [selectedSectionId, language, moveListItemToCenter]);

  return (
    <Box
      sx={{
        paddingY: 1,
        width: '100%',
        maxWidth: `${CONTAINER_MAX_WIDTH_MOBILE}px`,
        boxShadow: `0px 10px 6px -6px ${COLOR.Gray}`,
      }}
    >
      <div onMouseLeave={dragState.current.dragStop}>
        <ScrollMenu
          apiRef={apiRef}
          onMouseDown={() => dragState.current.dragStart}
          onMouseUp={({ getItemById, scrollToItem, items }: ScrollVisibilityApiType) =>
            () => {
              // NOTE: for center items
              dragState.current.dragStop();
              // @ts-ignore
              const { center } = getItemsPos(items.getVisible());
              scrollToItem(getItemById(center), 'smooth', 'center');
            }}
        >
          {menuContent?.sections?.map(({ id, name }) => (
            <ScrollLink
              key={id}
              onClick={() => handleScrollTo(id)}
              selected={id === selectedSectionId}
            >
              {name[language]}
            </ScrollLink>
          ))}
        </ScrollMenu>
      </div>
    </Box>
  );
};

export default SectionLinks;
